import { usePdfViewer } from '@cfra-nextgen-frontend/shared/src/hooks/usePdfViewer';
import { useParams, useSearchParams } from 'react-router-dom';

export function PdfViewer() {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') ?? undefined;
    const alert_id = searchParams.get('alert_id') ?? undefined;

    if (!id || !source || !alert_id) {
        throw new Error('Missing required parameters: id, source and alert_id are required');
    }

    usePdfViewer(id, source, alert_id);
    return null;
}
