import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { TopNavigation } from 'features/topNavigation';
import { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export const PATHS_WITHOUT_TOP_NAV = ['/pdf/'];

export function AuthenticatedRoutesWrapper() {
    const { pathname } = useLocation();
    const { userEntitlements } = useUserEntitlements();

    const hideTopNavigation = useMemo(() => {
        return PATHS_WITHOUT_TOP_NAV.some((path) => pathname.startsWith(path));
    }, [pathname]);

    const Navigation = useMemo(() => {
        if (hideTopNavigation) {
            return null;
        }
        return <TopNavigation userEntitlements={userEntitlements} />;
    }, [hideTopNavigation, userEntitlements]);

    return (
        <>
            {Navigation}
            <Outlet />
        </>
    );
}
