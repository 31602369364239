import { AppRoutes as SharedAppRoutes } from '@cfra-nextgen-frontend/shared/src/routes/AppRoutes';
import { RouteType } from '@cfra-nextgen-frontend/shared/src/routes/types';
import { setUpAwsAmplifyRememberMeListener } from '@cfra-nextgen-frontend/shared/src/routes/utils';
import { exportSSRAgGrid } from 'components/excelExport/export';
import { getDataSource, getSsrDataExportFn } from 'components/screener/ssr';
import { AccountingRoutes } from 'features/accountingLens/routes/AccountingRoutes';
import { Login } from 'features/auth/components/Login';
import { BespokeRoutes } from 'features/bespoke/routes/BespokeRoutes';
import { CompanyProfileRoutes } from 'features/companyProfile/routes/CompanyProfileRoutes';
import { ContactUsRoutes } from 'features/contactUs/routes/ContactUsRoutes';
import { HomeRoutes } from 'features/home';
import { LegalEdgeRoutes } from 'features/legalEdge/routes/LegalEdgeRoutes';
import { PdfViewerRoutes } from 'features/pdfViewer/routes/PdfViewerRoutes';
import { ResearchHubRoutes } from 'features/researchHub/routes/ResearchHubRoutes';
import { ScoresRoutes } from 'features/scores/routes/ScoresRoutes';
import { WatchlistRoutes } from 'features/watchlist/routes/WatchlistRoutes';
import { AuthenticatedRoutesWrapper } from 'routes/AuthenticatedRoutesWrapper';
import { sendSingleInfiniteRequest, sendSingleRequest } from 'utils/api';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';

const routes: Array<RouteType> = [
    { path: '*', element: <HomeRoutes /> },
    { path: '/company-profile/*', element: <CompanyProfileRoutes /> },
    {
        path: `${PageNamesToRoutePaths[PageNames.ResearchHub]}/*`,
        element: <ResearchHubRoutes />,
    },
    { path: '/watchlist/*', element: <WatchlistRoutes /> },
    { path: '/accounting-lens/*', element: <AccountingRoutes /> },
    {
        path: `${PageNamesToRoutePaths[PageNames.Scores]}/*`,
        element: <ScoresRoutes />,
    },
    {
        path: `${PageNamesToRoutePaths[PageNames.Bespoke]}/*`,
        element: <BespokeRoutes />,
    },
    {
        path: `${PageNamesToRoutePaths[PageNames.LegalEdge]}/*`,
        element: <LegalEdgeRoutes />
    },
    {
        path: `/contact-us/`,
        element: <ContactUsRoutes />
    },
    {
        path: `/pdf/*`,
        element: <PdfViewerRoutes />,
    },
];

export function AppRoutes() {
    return (
        <SharedAppRoutes
            callbackOnFirstRender={setUpAwsAmplifyRememberMeListener}
            loginElement={<Login />}
            authenticatedRoutesJsxGetterProps={{
                authenticatedRoutesWrapperJsx: <AuthenticatedRoutesWrapper />,
                projectSpecificResources: {
                    sendSingleRequest,
                    sendSingleInfiniteRequest,
                    getDataSource,
                    getSsrDataExportFn,
                    exportSSRAgGrid,
                },
                routes,
            }}
        />
    );
}
