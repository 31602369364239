import { Navigate, Route, Routes } from 'react-router-dom';
import { PdfViewer } from '../components/PdfViewer';

export function PdfViewerRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route path='/:id' element={<PdfViewer />} />
        </Routes>
    );
}
